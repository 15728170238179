import "@expo/browser-polyfill";
import "intl-pluralrules";

import * as SentryBrowser from "@sentry/browser";
import * as Sentry from "@sentry/react";
import constants from "expo-constants";
import * as Crypto from "expo-crypto";
import React, { useCallback, useEffect, useState } from "react";
import * as ReactNative from "react-native";
import { Platform } from "react-native";
import { setCrypto } from "sutro-common";
import { InterpreterBundle } from "sutro-common/interpreter/bundle";
import {
  BuildInProgress,
  globalNamespace,
  inIframe,
  reloadApp,
  SentryErrorBoundaryFallbackComponent,
  StudioComm,
  SutroAppContainer,
} from "sutro-interpreter";

import { loadInterpreterBundle } from "./loadInterpreterBundle";

globalNamespace.React = React;
globalNamespace.ReactNative = ReactNative;

import {
  STUDIO_PREVIEW_MESSAGE_TYPES,
  StudioCommLayer,
} from "sutro-interpreter/src/studio-comm-layer/types";
import useConstant from "use-constant";

import customComponents from "./custom-components.js";
setCrypto(Crypto);

const sentryConfig = {
  dsn: "https://f877824439694459ad6f34c1f3b4722d@o1183205.ingest.sentry.io/6335879",
  tracesSampleRate: 0.5,
  enabled:
    constants.expoConfig?.extra?.isDev !== true &&
    constants.expoConfig?.extra?.isProdTest !== true,
  integrations: [],
};

if (Platform.OS === "web") {
  SentryBrowser.init({
    ...sentryConfig,
    integrations: [
      ...sentryConfig.integrations,
      // This is only for web
      new SentryBrowser.Replay({
        // this was discussed in person, will be changed to dynamic value once we setup sentry releases
        sessionSampleRate: 0.25,
        errorSampleRate: 0.5,
      }),
    ],
  });
} else {
  Sentry.init(sentryConfig);
}

export default function App() {
  const [interpreterBundle, setInterpreterBundle] =
    useState<InterpreterBundle | null>(null);
  const [scheme, setScheme] = useState<ReactNative.ColorSchemeName | undefined>(
    undefined
  );

  const studioCommLayer = useConstant<StudioCommLayer | null>(() =>
    inIframe() ? new StudioComm() : null
  );

  useEffect(() => {
    studioCommLayer?.onMessage(
      STUDIO_PREVIEW_MESSAGE_TYPES.BUNDLE,
      (message) => {
        setInterpreterBundle(message.payload as unknown as InterpreterBundle);
      }
    );
    studioCommLayer?.onMessage(
      STUDIO_PREVIEW_MESSAGE_TYPES.SCHEME,
      (message) => {
        setScheme(message.payload);
      }
    );
    // Cleanup function to remove the event listener when the provider unmounts
    return () => {
      studioCommLayer?.cleanUp();
    };
  }, [studioCommLayer]);

  const tryAgain = useCallback(() => {
    loadInterpreterBundle()
      // Don't override bundle if value has already been set
      .then((value) => setInterpreterBundle((oldValue) => oldValue || value))
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (interpreterBundle !== null) {
      return;
    }
    tryAgain();
  }, [tryAgain, interpreterBundle]);

  if (interpreterBundle === null) {
    if (inIframe()) {
      return <ReactNative.ActivityIndicator />;
    }
    return <BuildInProgress tryAgain={tryAgain} />;
  }
  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <SentryErrorBoundaryFallbackComponent
          errorData={errorData}
          reload={reloadApp}
          theme={interpreterBundle?.theme}
        />
      )}
    >
      <SutroAppContainer
        interpreterBundle={interpreterBundle}
        customComponents={customComponents as Record<string, React.FC>}
        scheme={scheme}
        studioCommLayer={studioCommLayer}
      />
    </Sentry.ErrorBoundary>
  );
}
